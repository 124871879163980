<template>
  <v-container fluid class="pa-0">
    <v-tabs dense v-model="tab_details">
      <v-tab :value="0">ANUAL</v-tab>
      <v-tab :value="1">MENSUAL</v-tab>
    </v-tabs>
    <v-tabs-items
      style="font-size: 0.9rem;flex-grow: 1;"
      class="py-1"
      v-model="tab_details"
    >
      <v-tab-item :value="0">
        <v-simple-table :class="'tab ' + ($vuetify.theme.dark ? ' darkTable' : ' lightTable')">
          <thead>
            <tr>
              <th style="width:300px"></th>
              <th style="width:150px;border-right: 5px solid var(--v-primary-base)" v-for="(y,i) in years" :colspan="i == 0 ? 2 : 4">
                <h1 style="white-space: nowrap;" class="text-center">{{ y }}</h1>
              </th>
              <th class="primary--text text-center" colspan="2"><h1 style="white-space: nowrap;" class="d-flex justify-space-between alin-center">{{ Math.max(...years)+1 }}<span><v-btn class="me-1" fab x-small color="error" @click.stop="nuevo = {}"><v-icon>mdi-delete</v-icon></v-btn><v-btn @click.stop="saveNewPresupuesto" fab x-small color="primary"><v-icon>mdi-floppy</v-icon></v-btn></span></h1></th>
            </tr>
            <tr>
              <th><h2 class="primary--text">CUENTA PyG</h2></th>
              <template v-for="(y,i) in years">
                <th class="text-center" style="width: 100px;">
                  {{ y }}
                </th>
                <template v-if="i != 0">
                  <th style="width: 50px;">RATIOS</th>
                  <th colspan="2" class="text-center" style="border-right:5px solid var(--v-primary-base)">DIF</th>
                </template>
                <th v-else style="width: 50px;border-right:5px solid var(--v-primary-base)">RATIOS</th>
              </template>
              <th>VALORES</th>
              <th style="width: 100px;">RATIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Ventas</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['ventas']"></v-text-field>€</td>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['ventas']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right:1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['ventas'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['ventas']) < Math.round(presupuestos.find(p => p.year == y-1)['ventas'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['ventas']) - Math.round(presupuestos.find(p => p.year == y-1)['ventas'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['ventas']) - Math.round(presupuestos.find(p => p.year == y-1)['ventas'])) / (Math.round(presupuestos.find(p => p.year == y)['ventas']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['ventas']) - Math.round(presupuestos.find(p => p.year == y-1)['ventas'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['ventas']) - Math.round(presupuestos.find(p => p.year == y-1)['ventas'])) / (Math.round(presupuestos.find(p => p.year == y)['ventas']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right:5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['ventas'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round(nuevoVentas || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.ventas" dense hide-details="" label="Dif AA"></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Aprovisionamientos</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['aprovisionamientos']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']) < Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']) - Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']) - Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos'])) / (Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']) - Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['aprovisionamientos']) - Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos'])) / (Math.round(presupuestos.find(p => p.year == y-1)['aprovisionamientos']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.aprovisionamientos" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Margen Bruto</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(nuevoVentas + ((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(((nuevoVentas + ((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100))) / (nuevoVentas + nuevoOie)) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Otros Ingresos Expl.</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['oie']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['oie']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['oie'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['oie']) < Math.round(presupuestos.find(p => p.year == y-1)['oie'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['oie']) - Math.round(presupuestos.find(p => p.year == y-1)['oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['oie']) - Math.round(presupuestos.find(p => p.year == y-1)['oie'])) / (Math.round(presupuestos.find(p => p.year == y-1)['oie']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['oie']) - Math.round(presupuestos.find(p => p.year == y-1)['oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['oie']) - Math.round(presupuestos.find(p => p.year == y-1)['oie'])) / (Math.round(presupuestos.find(p => p.year == y-1)['oie']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['oie'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round(nuevoOie || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.oie" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Gastos Personal</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['gastosPersonal']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['gastosPersonal'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']) < Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']) - Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal']))* -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']) - Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal'])) / (Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']) - Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal']))* -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['gastosPersonal']) - Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal'])) / (Math.round(presupuestos.find(p => p.year == y-1)['gastosPersonal']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['gastosPersonal'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.gastosPersonal" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">O.C.E. y GG</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['ocegg']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['ocegg']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['ocegg'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['ocegg'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg']))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg']))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['ocegg'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['ocegg'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.ocegg" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">EBITDA</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'],['ventas'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg'],['ventas'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) / (nuevoVentas)) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Amortización</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['amortizacion']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['amortizacion']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['amortizacion'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['amortizacion'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacion'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacion'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacion'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacion'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['amortizacion'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.amortizacion" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Imputación Subv. Inm No financ.</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['imputacionSubv']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['imputacionSubv']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['imputacionSubv'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) < Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['imputacionSubv'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.imputacionSubv" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Resultados Extraord.</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['resultadosExtraord']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['resultadosExtraord'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['resultadosExtraord'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['resultadosExtraord'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['resultadosExtraord'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.resultadosExtraord" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">EBIT - Rtdo Explotación </td>
              <template v-for="(y,i) in years">
                <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100))) / (nuevoVentas + nuevoOie)) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Resultado Financiero</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['resultadoFinanciero']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['resultadoFinanciero'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['resultadoFinanciero'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['resultadoFinanciero'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['resultadoFinanciero'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.resultadoFinanciero" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">BAI (BAT)</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100))) / (nuevoVentas + nuevoOie)) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">IS Sociedades</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['isSociedades']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['isSociedades']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['isSociedades'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['isSociedades'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['isSociedades'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['isSociedades'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['isSociedades'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['isSociedades'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['isSociedades'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.isSociedades" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">BENEFICIO NETO (Rtdo Ejercicio)</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 5px solid var(--v-primary-base)">{{ Math.round(calculatePercentage(presupuestos.find(p => p.year == y),['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'],['ventas','oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} %</td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100))) / (nuevoVentas + nuevoOie)) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th><h2 class="primary--text">PUNTO MUERTO</h2></th>
              <template v-for="(y,i) in years">
                <th class="text-center" style="width: 100px;">
                  {{ y }}
                </th>
                <template v-if="i != 0">
                  <th style="width: 50px;">RATIOS</th>
                  <th colspan="2" class="text-center" style="border-right:5px solid var(--v-primary-base)">DIF</th>
                </template>
                <th v-else style="width: 50px;border-right:5px solid var(--v-primary-base)">RATIOS</th>
              </template>
              <th>VALORES</th>
              <th style="width: 100px;">RATIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Punto Muerto (€)</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1)) < Math.round(-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) - (-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(((-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0) - Math.round(((-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0)) / (Math.round(((-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) - (-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(((-(calculateSum(presupuestos.find(p => p.year == y), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0) - Math.round(((-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0)) / (Math.round(((-(calculateSum(presupuestos.find(p => p.year == y-1), ['amortizacion', 'ocegg', 'gastosPersonal'])) / ((1-(-(Math.round(calculatePercentage(presupuestos.find(p => p.year == y-1), ['aprovisionamientos'], ['ventas','oie'])) / 100))) || 1))) || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(-(((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100))) / (1-(-(Math.round((nuevo.aprovisionamientos || 0) / 100))) || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th><h2 class="primary--text">GASTOS FINANCIEROS</h2></th>
              <template v-for="(y,i) in years">
                <th class="text-center" style="width: 100px;">
                  {{ y }}
                </th>
                <template v-if="i != 0">
                  <th style="width: 50px;">RATIOS</th>
                  <th colspan="2" class="text-center" style="border-right:5px solid var(--v-primary-base)">DIF</th>
                </template>
                <th v-else style="width: 50px;border-right:5px solid var(--v-primary-base)">RATIOS</th>
              </template>
              <th>VALORES</th>
              <th style="width: 100px;">RATIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Intereses</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['intereses']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['intereses']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['intereses'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['intereses'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['intereses'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['intereses'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['intereses'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['intereses'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.intereses || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.intereses" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Amortización Principal</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['amortizacionPrincipal']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0) < Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.amortizacionPrincipal || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.amortizacionPrincipal" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">GASTOS FINANCIEROS</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal']))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal']))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['intereses', 'amortizacionPrincipal'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['intereses', 'amortizacionPrincipal'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(((nuevoVentas + nuevoOie) * ((nuevo.intereses || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacionPrincipal || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th><h2 class="primary--text">CASH FLOWS</h2></th>
              <template v-for="(y,i) in years">
                <th class="text-center" style="width: 100px;">
                  {{ y }}
                </th>
                <template v-if="i != 0">
                  <th style="width: 50px;">RATIOS</th>
                  <th colspan="2" class="text-center" style="border-right:5px solid var(--v-primary-base)">DIF</th>
                </template>
                <th v-else style="width: 50px;border-right:5px solid var(--v-primary-base)">RATIOS</th>
              </template>
              <th>VALORES</th>
              <th style="width: 100px;">RATIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="white-space: nowrap;">Beneficio Neto</td>
              <template v-for="(y,i) in years">
                <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) < Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) - Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades']))) / (Math.round(calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Amortizaciones</td>
              <template v-for="(y,i) in years">
                <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) < Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round(((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100))*-1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">Imputación Subv. Inm No financ.</td>
              <template v-for="(y,i) in years">
                <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) < Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)) - Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) / (Math.round(-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"></td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">IS Sociedades (bases negativas)</td>
              <template v-for="(y,i) in years">
                <td style="border-right: 1px solid rgb(144, 144, 144);white-space: nowrap">
                  <v-text-field type="number" class="noArrow" v-model="presupuestos.find(p => p.year == y)['isSociedades']" dense hide-details=""></v-text-field>
                </td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['isSociedades']) < Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['isSociedades']) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['isSociedades']) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'])) / (Math.round(presupuestos.find(p => p.year == y-1)['isSociedades']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['isSociedades']) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['isSociedades']) - Math.round(presupuestos.find(p => p.year == y-1)['isSociedades'])) / (Math.round(presupuestos.find(p => p.year == y-1)['isSociedades']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.isSociedades" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">FCF OPERATIVO</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))) < Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))) - Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))) - Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))) / (Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))) - Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0))) - Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))) / (Math.round((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0))) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + nuevoVentas + nuevoOie + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100)) + (-((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100))) + (-((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Deuda (amort. Principal)</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['amortizacionPrincipal']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']) < Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'])) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['amortizacionPrincipal']) - Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'])) / (Math.round(presupuestos.find(p => p.year == y-1)['amortizacionPrincipal']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ ((nuevoVentas + nuevoOie) * ((nuevo.amortizacionPrincipal || 0) / 100)) }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Inversiones Capex</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['inversionesCapex']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']) < Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex'])) / (Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['inversionesCapex']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex'])) / (Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapex']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapex || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.inversionesCapex" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Financiación E.F.</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['financiacionEF']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['financiacionEF']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['financiacionEF']) < Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['financiacionEF']) - Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['financiacionEF']) - Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF'])) / (Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['financiacionEF']) - Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['financiacionEF']) - Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF'])) / (Math.round(presupuestos.find(p => p.year == y-1)['financiacionEF']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.financiacionEF || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.financiacionEF" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Inversiones Capital Trabajo</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']) < Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'])) / (Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo']) - Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'])) / (Math.round(presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapitalTrabajo || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.inversionesCapitalTrabajo" dense hide-details=""></v-text-field></td>
             </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Ingreso extraord periodificado</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']) < Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']) - Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']) - Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'])) / (Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']) - Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado']) - Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'])) / (Math.round(presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.ingresoExtraordPeriodificado || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.ingresoExtraordPeriodificado" dense hide-details=""></v-text-field></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Anticipos Proveedores</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['anticiposProveedores']"></v-text-field>€</td>
                <td v-else style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']) < Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']) - Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']) - Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores'])) / (Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']) - Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores'])) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y)['anticiposProveedores']) - Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores'])) / (Math.round(presupuestos.find(p => p.year == y-1)['anticiposProveedores']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.anticiposProveedores || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.anticiposProveedores" dense hide-details=""></v-text-field></td>
            </tr> 
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">FREE CASH FLOWS</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ 
                  Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                  }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)) < Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0))) / (Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0))) / (Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ 
                Math.round(((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100)) + (-((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100))) + (-((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)))) -
               ((nuevoVentas + nuevoOie) * ((nuevo.amortizacionPrincipal || 0) / 100)) -
               ((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapex || 0) / 100)) -
               ((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapitalTrabajo || 0) / 100)) +
               ((nuevoVentas + nuevoOie) * ((nuevo.anticiposProveedores || 0) / 100)) +
               ((nuevoVentas + nuevoOie) * ((nuevo.financiacionEF || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">FREE CASH FLOWS ACUMULADOS</td>
              <template v-for="(y,i) in years">
                <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ 
                  Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                  }} €</td>
                <template v-if="i != 0">
                  <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)) < Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0))">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0))) / (Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(((calculateSum(presupuestos.find(p => p.year == y), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y)['ingresoExtraordPeriodificado'] || 0)) - Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0))) / (Math.round(((calculateSum(presupuestos.find(p => p.year == y-1), ['aprovisionamientos', 'ventas', 'oie', 'gastosPersonal', 'ocegg', 'amortizacion', 'imputacionSubv', 'resultadosExtraord', 'resultadoFinanciero', 'isSociedades'])) + (-(presupuestos.find(p => p.year == y-1)['amortizacion'] || 0)) + (-(presupuestos.find(p => p.year == y-1)['imputacionSubv'] || 0)))-
                  (presupuestos.find(p => p.year == y-1)['amortizacionPrincipal'] || 0)-
                  (presupuestos.find(p => p.year == y-1)['inversionesCapex'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['financiacionEF'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['inversionesCapitalTrabajo'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['anticiposProveedores'] || 0)+
                  (presupuestos.find(p => p.year == y-1)['ingresoExtraordPeriodificado'] || 0)) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ 
                Math.round(((((nuevoVentas + nuevoOie) * ((nuevo.aprovisionamientos || 0) / 100)) + (nuevoVentas + nuevoOie) + ((nuevoVentas + nuevoOie) * ((nuevo.gastosPersonal || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.ocegg || 0) / 100))) + ((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadosExtraord || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.resultadoFinanciero || 0) / 100)) + ((nuevoVentas + nuevoOie) * ((nuevo.isSociedades || 0) / 100)) + (-((nuevoVentas + nuevoOie) * ((nuevo.amortizacion || 0) / 100))) + (-((nuevoVentas + nuevoOie) * ((nuevo.imputacionSubv || 0) / 100)))) -
                  ((nuevoVentas + nuevoOie) * ((nuevo.amortizacionPrincipal || 0) / 100)) -
                  ((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapex || 0) / 100)) +
                  ((nuevoVentas + nuevoOie) * ((nuevo.inversionesCapitalTrabajo || 0) / 100)) +
                  ((nuevoVentas + nuevoOie) * ((nuevo.anticiposProveedores || 0) / 100)) +
                  ((nuevoVentas + nuevoOie) * ((nuevo.ingresoExtraordPeriodificado || 0) / 100))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              }}</td>
              <td></td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th><h2 class="primary--text">TESORERÍA</h2></th>
              <template v-for="(y,i) in years">
                <th class="text-center" style="width: 100px;">
                  {{ y }}
                </th>
                <template v-if="i != 0">
                  <th style="width: 50px;">RATIOS</th>
                  <th colspan="2" class="text-center" style="border-right:5px solid var(--v-primary-base)">DIF</th>
                </template>
                <th v-else style="width: 50px;border-right:5px solid var(--v-primary-base)">RATIOS</th>
              </template>
              <th>VALORES</th>
              <th style="width: 100px;">RATIOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Tesorería Acumulada (balance)</td>
              <template v-for="(y,i) in years">
                <td v-if="editing" class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)"><v-text-field dense hide-details="" v-model="presupuestos.find(p => p.year == y)['tesoreriaAcumulada']"></v-text-field> €</td>
                <td v-else class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada']).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}} €</td>
                <template v-if="i != 0">
                  <td style="border-right:1px solid rgb(144, 144, 144)"></td>
                  <template v-if="Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada']) < Math.round(presupuestos.find(p => p.year == y-1)['tesoreriaAcumulada'])">
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y-1)['tesoreriaAcumulada']) - Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y-1)['tesoreriaAcumulada']) - Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada'])) / (Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada']) || 1)) * 100) }} %</td>
                  </template>
                  <template v-else>
                      <td style="border-right:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(presupuestos.find(p => p.year == y-1)['tesoreriaAcumulada']) - Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                      <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(presupuestos.find(p => p.year == y-1)['tesoreriaAcumulada']) - Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada'])) / (Math.round(presupuestos.find(p => p.year == y)['tesoreriaAcumulada']) || 1)) * 100) }} %</td>
                  </template>
                </template>
                <td v-else style="border-right: 5px solid var(--v-primary-base)"></td>
              </template>
              <td style="white-space: nowrap;">{{ Math.round((nuevoVentas + nuevoOie) * ((nuevo.tesoreriaAcumulada || 0) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo.tesoreriaAcumulada" dense hide-details=""></v-text-field></td>
            </tr>
          </tbody>
        </v-simple-table>
        
        <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
          <v-btn :color="editing ? 'error' : 'primary'" fab @click="editing = !editing" large class="me-4">
            <v-icon>{{ editing ? 'mdi-pencil-off' : 'mdi-pencil'}}</v-icon>
          </v-btn>
          <!-- <v-spacer></v-spacer>
          <v-btn color="primary" fab @click="addEstudio" large class="me-4">
            <v-icon>mdi-upload</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab @click="addComparada" large>
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
        </div>
      </v-tab-item>
      <v-tab-item :value="1">
        <v-simple-table>
          <thead>
            <tr>
              <th rowspan="2">CUENTA PyG</th>
              <th colspan="2" class="text-center">REAL</th>
              <th colspan="2" class="text-center">PPTO {{ months[selectedMonth] }} {{ selectedYear }}</th>
              <th colspan="2" class="text-center">DIF</th>
            </tr>
            <tr>
              <th class="text-center">{{ selectedYear }}</th>
              <th class="text-center">RATIO</th>
              <th class="text-center">PPTO MES</th>
              <th class="text-center">RATIO</th>
              <th class="text-center">DIF €</th>
              <th class="text-center">DIF %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ventas</td>
              <td><v-text-field type="number" v-model="pptoMes.ventas" hide-details="" dense></v-text-field></td>
              <td>{{ Math.round(calculatePercentage(pptoMes, ['ventas'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td>{{ Math.round(((presupuestos.find(y => y.year == selectedYear)?.ventas || 0) / 12) * (selectedMonth+1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td v-if="presupuestos.some(y => y.year == selectedYear)">{{ Math.round(calculatePercentage(presupuestos.find(y => y.year == selectedYear), ['ventas'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td v-else>0 %</td>
              <td>{{ Math.round((pptoMes.ventas || 0) - (((presupuestos.find(y => y.year == selectedYear)?.ventas || 0) / 12) * (selectedMonth+1))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td>{{ Math.round((((pptoMes.ventas || 0) - (((presupuestos.find(y => y.year == selectedYear)?.ventas || 0) / 12) * (selectedMonth+1))) / (((presupuestos.find(y => y.year == selectedYear)?.ventas || 0) / 12) * (selectedMonth+1))) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
            <tr>
              <td>Aprovisionamientos</td>
              <td><v-text-field type="number" v-model="pptoMes.aprovisionamientos" hide-details="" dense></v-text-field></td>
              <td>{{ Math.round(calculatePercentage(pptoMes, ['aprovisionamientos'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td>{{ Math.round(((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td v-if="presupuestos.some(y => y.year == selectedYear)">{{ Math.round(calculatePercentage(presupuestos.find(y => y.year == selectedYear), ['aprovisionamientos'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td v-else>0 %</td>
              <td>{{ Math.round((pptoMes.aprovisionamientos || 0) - (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td>{{ Math.round((((pptoMes.aprovisionamientos || 0) - (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))) / (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
            <tr>
              <td>Margen Bruto</td>
              <td>{{ Math.round(calculateSum(pptoMes, ['ventas', 'aprovisionamientos'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td>{{ Math.round(calculatePercentage(pptoMes, ['ventas', 'aprovisionamientos'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td>{{ Math.round(calculateSum(presupuestos.find(p => p.year == selectedYear), ['aprovisionamientos', 'ventas'])) }}</td>
              <td v-if="presupuestos.some(y => y.year == selectedYear)">{{ Math.round(calculatePercentage(presupuestos.find(y => y.year == selectedYear), ['aprovisionamientos', 'ventas'], ['ventas', 'oie'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
              <td v-else>0 %</td>
              <td>{{ Math.round((pptoMes.aprovisionamientos || 0) - (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
              <td>{{ Math.round((((pptoMes.aprovisionamientos || 0) - (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))) / (((presupuestos.find(y => y.year == selectedYear)?.aprovisionamientos || 0) / 12) * (selectedMonth+1))) * 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
// import * as xlsx from 'xlsx';
// import FileSaver from 'file-saver';
// import * as ExcelJS from "exceljs";

export default {
  components: {
    SelectEstudio: () => import("../components/SelectEstudio.vue"),
    NuevoEstudio: () => import("../components/NuevoEstudio.vue"),
  },
  data(){
    return {
      presupuestos:[],
      years:[],
      tab_details: 0,
      nuevo: {},
      pptoMes: {},
      selectedYear: new Date().getFullYear()-1,
      selectedMonth: new Date().getMonth(),
      months: ["ENE", "FEB", "MAR", "ABR", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
      editing: false,
    }
  },
  computed:{
    // nuevoOie() {
    //   const latestYear = Math.max(...this.years);
    //   const baseOie = this.presupuestos.find(p => p.year === latestYear)?.oie || 0;
    //   const calculatedOie = Math.round(baseOie * (1 + (this.nuevo.oie || 0) / 100)) || 0;
    //   return calculatedOie;
    // },
    nuevoOie(){
      let ventas = this.nuevoVentas;
      let porcentaje = this.nuevo.oie || 0;
      let oie = 0;
      let delta = 0.001;
      let diferencia = Infinity;

      while (diferencia > delta) {
          let nuevoOie = (porcentaje / 100) * (ventas + oie);
          diferencia = Math.abs(nuevoOie - oie);
          oie = nuevoOie;
      }
      return oie
    },
    nuevoVentas() {
      const latestYear = Math.max(...this.years);
      const baseOie = this.presupuestos.find(p => p.year === latestYear)?.ventas || 0;
      const calculatedOie = Math.round(baseOie * (1 + (this.nuevo.ventas || 0) / 100)) || 0;
      return calculatedOie;
    },
  },
  methods:{
    async update(key, emp){
      try {
        await axios({
          method: 'PUT',
          url: `${process.env.VUE_APP_API_URL}/competencia/presupuestos/${emp.year}`,
          data: emp[key]
        })
        this.$root.$emit("snack", "Se ha actualizado el presupuesto correctamente");
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se ha podido actualizar el presupuesto");
      }
    },
    async saveNewPresupuesto(){
      try {
        await axios({
          method: 'POST',
          url: `${process.env.VUE_APP_API_URL}/competencia/presupuesto`,
          data: this.nuevo
        })
        this.$root.$emit("snack", "Se ha guardado el presupuesto correctamente");
        this.comparadas.push(JSON.parse(JSON.stringify(this.nuevo)));
        this.nuevo = {}
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se ha podido guardar el presupuesto");
      }
    },
    calculateSum(emp, keys) {
      return keys.reduce((sum, key) => +sum + (+emp[key] || 0), 0);
    },
    calculatePercentage(emp, keys, total) {
      const tot = total.reduce((acc, key) => +acc + (+emp[key] || 0), 0);
      const sum = keys.reduce((acc, key) => +acc + (+emp[key] || 0), 0);
      return tot ? (sum / tot) * 100 : 0;
    },
    async getPresupuestos(){
      const { data } = await axios({
        url: `/competencia/presupuestos`,
      });
      this.presupuestos = data
    }
  },
  async mounted(){
    await Promise.all([
      this.getPresupuestos()
    ])
    this.years = this.presupuestos.map(x => x.year).sort((a,b) => a - b)
  }
};
</script>

<style>
.tab table{
  width: auto !important;
}
.tab.darkTable tbody tr:nth-child(even) td {
    background-color: rgb(50, 50, 50) !important;
}
.tab.lightTable tbody tr:nth-child(even) td {
    background-color: rgb(225, 225, 225) !important;
}
.tab.darkTable tbody tr:nth-child(odd) td {
    background-color: rgb(30, 30, 30) !important;
}
.tab.lightTable tbody tr:nth-child(odd) td {
    background-color: rgb(255, 255, 255) !important;
}
.noArrow input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.noArrow input::-webkit-outer-spin-button,
.noArrow input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>